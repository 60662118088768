
import Page from "@/core-ui/Page.vue";
import { defineComponent, ref } from "vue";
import Button from "@/core-ui/Button.vue";
import authStore from "@/stores/authStore";

export default defineComponent({
    components: { Page, Button },
    computed: {
        userHasRoles(): boolean {
            return authStore.userInfo.roles?.length > 0 || false;
        },
        btnText(): string {
            return this.userHasRoles ? "Go to a safe place" : "Log out";
        },
    },
    methods: {
        navigateUser() {
            if (this.userHasRoles) {
                this.$router.push("/");
            } else {
                authStore.logout(true);
            }
        },
    },
});
